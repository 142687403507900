import React from "react";
import styled from "@emotion/styled";

export const SampleWrapper = (props) => {
  return <SWrapper>{props.txt}</SWrapper>;
};

const SWrapper = styled.section`
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;
