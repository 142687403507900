import React from "react";

import { DefaultLayout } from "../components/layout/defaultLayout";
import { SampleWrapper } from "../components/wrapper/sampleWrapper";

const ShoplistPage = () => {
  return (
    <DefaultLayout pageTitle="店舗一覧" headerTitle="Shop List">
      <SampleWrapper txt="店舗一覧ページ" />
    </DefaultLayout>
  );
};

export default ShoplistPage;
